/* Variables */
:root {
    --color-primary: rgba(104,41,106,1); /*#021d29;*/
    --color-hover: rgba(51, 23, 41, 1); /*#053F59;*/
    --color-duo: rgba(248, 10, 91, 1); /*#053F59;*/
    --color-tertiary: rgba(146, 86, 148, 1); /*#053F59;*/
    --color-quad: rgba(87, 27, 82, 1);
    --border-radius: 4px;
}

/* Gradient */
.gradient-text {
    background-image: linear-gradient(45deg, var(--color-duo) 0%, var(--color-primary) 50%, var(--color-quad) 75%, var(--color-tertiary) 100% );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: glow 5s linear infinite;
    background-size: 200%;
}
@keyframes glow {
    0% { background-position: 0% 43% }
    50% { background-position: 99% 71% }
    100% {background-position: 0% 43% }
}

/* Override external SVG's color using filters
   generate: https://jsfiddle.net/3tw2gjwt/52/
*/
.svgImgGreyscale, .svgImgGreyscale svg {
    fill: #888 !important;
    filter: invert(0.5) sepia(0) saturate(1) hue-rotate(0deg) !important;
}
